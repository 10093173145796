import { Checkbox, Col, Divider, Form, Layout, Radio, Row, Space, Spin, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";

import PrefixMissingData from '../../components/reports/PrefixMissingData.js';
import PubdateMissingData from '../../components/reports/PubdateMissingData.js';
import ValidMissingData from '../../components/reports/ValidMissingData.js';
import { apiCall } from "../../utils/Api";
import conditional from '../../utils/conditional.js';

export default function MissingDataPage() {

    const [radioDisplayValue, setRadioDisplayValue] = useState("");
    const [distributors, setDistributors] = useState([]);
    const [currentDist, setCurrentDist] = useState("");
    const [loading, setLoading] = useState(false);
    const [initLoaded, setInitLoaded] = useState(false);
    const [validPageData, setValidPageData] = useState([]);
    const [prefixPageData, setPrefixPageData] = useState([]);
    const [pubdatePageData, setPubdatePageData] = useState([]);

    const initialIsbnValues = {
        all_isbns: true,
        nyr_isbns: true,
        rank_isbns: true,
        ito_isbns: true,
        oh_isbns: true,
        oo_isbns: true,
    }
    
    const initialDataValues = {
        no_title: true,
        no_img: true,
        no_desc: true,
        no_bio: true,
        no_digitals: false,
        no_annotations: false,
        no_related_isbns: false,
        no_interiors: false,
    }

    const [isbnValues, setIsbnValues] = useState(initialIsbnValues)
    const [dataValues, setDataValues] = useState(initialDataValues)

    const grabDistributors = () => {
        setLoading(true)
        apiCall("missing_data/getDistributors", {}, (_status, _results) => {
            if(_status){
                setDistributors(_results)
                setCurrentDist(_results.supplier)
                setRadioDisplayValue("valid")
            }
        })
        let form_data = {
            supplier: currentDist,
            group: "valid"
        }
        apiCall("missing_data/get", form_data, (_status, _results) => {
            if(_status){
                setValidPageData(_results)
            }
            setLoading(false);
            setInitLoaded(true);
        })
    }

    useEffect(grabDistributors, [])

    function getDrilldown(grouping_row, group, drilldown_value){
        let args = {
            supplier: currentDist, // easy
            grouping_row: grouping_row, // grouping_row is y/n, the date, or the prefix
            group: group, // group is valid (none), prefix, or pubdate
            drilldown: drilldown_value, // drilldown is the specific oo_isbn, oh_isbn, etc.
        }
        apiCall("missing_data/getBrowseKeyForDrilldown", args, (_status, _results) => {
            if(_status){
                //once the API returns the list back, this will pop open a new page in a separate tab
                window.open("/browse/filter/x/" + _results.id + "/v/sequence", "_blank");
            }
        })
    }

    // function distributorSelectOptions(){
    //     if(distributors.hasOwnProperty("distributor_list")){
    //         let distributor_list = distributors.distributor_list.sort()
    //         return distributor_list.map((item, index) => {
    //             return (<Select.Option key={index} value={item}><small>{item}</small></Select.Option>)
    //         })
    //     }
    // }

    const drawISBNGroupBoxes = () => {
        return (
            <Space direction="vertical">
                <Space>
                    <Checkbox defaultChecked={isbnValues.all_isbns} onChange={(e) => setIsbnValues({...isbnValues, "all_isbns": e.target.checked})}>All ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.nyr_isbns} onChange={(e) => setIsbnValues({...isbnValues, "nyr_isbns": e.target.checked})}>NYR ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.rank_isbns} onChange={(e) => setIsbnValues({...isbnValues, "rank_isbns": e.target.checked})}>Rank ISBNs</Checkbox>
                </Space>
                <Space>
                    <Checkbox defaultChecked={isbnValues.ito_isbns} onChange={(e) => setIsbnValues({...isbnValues, "ito_isbns": e.target.checked})}>ITO ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.oh_isbns} onChange={(e) => setIsbnValues({...isbnValues, "oh_isbns": e.target.checked})}>OH ISBNs</Checkbox>
                    <Checkbox defaultChecked={isbnValues.oo_isbns} onChange={(e) => setIsbnValues({...isbnValues, "oo_isbns": e.target.checked})}>OO ISBNs</Checkbox>
                </Space>
            </Space>
        )
    }

    const drawISBNIndividualInfoBoxes = () => {
        return (
            <Space direction='vertical'>
                <Space>
                    <Checkbox defaultChecked={dataValues.no_title} onChange={(e) => setDataValues({...dataValues, "no_title": e.target.checked})}>No Titles</Checkbox>
                    <Checkbox defaultChecked={dataValues.no_img} onChange={(e) => setDataValues({...dataValues, "no_img": e.target.checked})}>No Images</Checkbox>
                    <Checkbox defaultChecked={dataValues.no_bio} onChange={(e) => setDataValues({...dataValues, "no_bio": e.target.checked})}>No Bio</Checkbox>
                    <Checkbox defaultChecked={dataValues.no_desc} onChange={(e) => setDataValues({...dataValues, "no_desc": e.target.checked})}>No Desc</Checkbox>
                </Space>
                <Space>
                    <Checkbox disabled defaultChecked={dataValues.no_interiors} onChange={(e) => setDataValues({...dataValues, "no_interiors": e.target.checked})}>No Interiors</Checkbox>
                    <Checkbox disabled defaultChecked={dataValues.no_digitals} onChange={(e) => setDataValues({...dataValues, "no_digitals": e.target.checked})}>No Digitals</Checkbox>
                    <Checkbox disabled defaultChecked={dataValues.no_annotations} onChange={(e) => setDataValues({...dataValues, "no_annotations": e.target.checked})}>No Annotations</Checkbox>
                    <Checkbox disabled defaultChecked={dataValues.no_related_isbns} onChange={(e) => setDataValues({...dataValues, "no_related_isbns": e.target.checked})}>No Related ISBNs</Checkbox>
                </Space>
            </Space>
        )
    }

    const drawTitle = () => {
        if(radioDisplayValue === "prefix"){
            return (
                <div>
                    Missing Data, Sorted by ISBN Prefix
                </div>
            )
        }
        if(radioDisplayValue === "pubdate"){
            return (
                <div>
                    Missing Data, Sorted by Pubdate
                </div>
            )
        }
        return (
            <div>
                Missing Data, Sorted by Summary of ISBN Type
            </div>
        )
    }

    function changeDisplayedData(chosen_option){
        setLoading(true)
        setRadioDisplayValue(chosen_option);
        let form_data = {
            supplier: currentDist,
            group: chosen_option
        }
        apiCall("missing_data/get", form_data, (_status, _results) => {
            if(_status){
                if(chosen_option === "valid"){
                    setValidPageData(_results)
                } else if(chosen_option === "prefix"){
                    setPrefixPageData(_results)
                } else {
                    setPubdatePageData(_results)
                }
            }
            setLoading(false)
        })
        
    }

    const drawFilters = () => {
        return (
            <div style={{"padding": "20px 20px" }}>
                <Row>
                    <Typography.Title style={{ "lineHeight": "17px" }} level={5}>
                        {drawTitle()}
                    </Typography.Title>
                    <Divider dashed style={{ "marginTop": "10px", "marginBottom": "15px" }} />
                    <Col span={5} >
                        <Form size="small" layout="vertical">
                            {/* <Space wrap size={[10, 0]}> */}
                            <Space >
                                <Form.Item label={<small>Group Results By:</small>} name="group">
                                    <Radio.Group 
                                        value={radioDisplayValue} 
                                        defaultValue={radioDisplayValue} 
                                        onChange={(e) => changeDisplayedData(e.target.value)}>
                                        <Tooltip title="Data displayed in a summarized view, split between being a Valid ISBN and Non-Valid ISBN">
                                            <Radio.Button value={"valid"}>Summary</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="ISBNs missing data listed by ISBN Prefixes">
                                            <Radio.Button value={"prefix"}>ISBN Prefix</Radio.Button>
                                        </Tooltip>
                                        <Tooltip title="ISBNs grouped by their publish dates">
                                            <Radio.Button value={"pubdate"}>Pubdate</Radio.Button>
                                        </Tooltip>
                                    </Radio.Group>
                                </Form.Item>
                                {/* <conditional.true value={distributors.distributor_list.length > 1}>
                                    <Form.Item label={<small>Pick a Pubstock Vendor to see their Reports</small>} name="supplier" initialValue={currentDist}>
                                        <Select value={currentDist} initialValue={currentDist} onChange={(e) => setCurrentDist(e)}>
                                            {distributorSelectOptions()}
                                        </Select>
                                    </Form.Item>
                                </conditional.true> */}
                            </Space>
                            <div className="shim"></div>
                        </Form>
                    </Col>
                    <Col span={18} offset={1}>
                        <conditional.true value={radioDisplayValue === "prefix" || radioDisplayValue === "pubdate"}> 
                            <Row>
                                <Col span={10} className="columnSplitter">
                                    <Tooltip title="Individual types of ISBNs can be toggled on and off">
                                        <Divider plain>
                                            <small className="c" 
                                                style={{ "fontWeight": "400", "fontSize": "16px" }}>ISBN Types
                                            </small>
                                        </Divider> 
                                    </Tooltip>
                                    {drawISBNGroupBoxes()}
                                </Col>
                                <Col span={13}>
                                    <Tooltip title="Some of the different types of data that can be missing from an ISBN">
                                        <Divider plain>
                                            <small className="c" 
                                                style={{ "fontWeight": "400", "fontSize": "16px" }}>Data Types
                                            </small>
                                        </Divider> 
                                    </Tooltip>
                                    {drawISBNIndividualInfoBoxes()}
                                </Col>
                            </Row>
                        </conditional.true>
                        <conditional.true value={radioDisplayValue === "valid"}>
                            <Row align="middle" justify="center">
                                <Tooltip title="Some of the different types of data that can be missing from an ISBN">
                                    <Divider plain>
                                        <small className="c" 
                                            style={{ "fontWeight": "400", "fontSize": "16px" }}>Data Types
                                        </small>
                                    </Divider> 
                                </Tooltip>
                                {drawISBNIndividualInfoBoxes()}
                            </Row>
                        </conditional.true>
                    </Col>
                </Row>
            </div>
        )
    }

    const drawValid = () => {
        return (
            <>
                <ValidMissingData
                    loading={loading}
                    getDrilldown={getDrilldown}
                    validPageData={validPageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }
    const drawPrefix = () => {
        return (
            <>
                <PrefixMissingData
                    loading={loading}
                    setLoading={setLoading}
                    getDrilldown={getDrilldown}
                    group={radioDisplayValue}
                    supplier={currentDist}
                    prefixPageData={prefixPageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }
    const drawPubdate = () => {
        return (
            <>
                <PubdateMissingData
                    loading={loading}
                    setLoading={setLoading}
                    getDrilldown={getDrilldown}
                    group={radioDisplayValue}
                    supplier={currentDist}
                    pubdatePageData={pubdatePageData}
                    isbnValues={isbnValues}
                    dataValues={dataValues}
                />
            </>
        )
    }
    
    //draws the layout of the page depending on the radioDisplayValue
    return (
        <>
            {/* Pre-load page, to display before all the APIs are done loading as they can take lots of time*/}
            {!initLoaded && <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{"paddingTop": "150px", "textAlign": "center" }} >
                    <Spin 
                        size="large"
                        tip="Retrieving large amounts of data... please wait" 
                        style={{ "minHeight": "200px" }}
                        spinning={!initLoaded}></Spin>
                </Layout.Content>
            </Layout> }

            {initLoaded && <Layout className="layout">
                <Layout.Content className="layoutHeader" style={{ "padding": "0px", "borderBottom": "0px",  }} >
                    <div style={{ "padding": "0px 0px" }}>
                        {drawFilters()}
                    </div>
                </Layout.Content>
                <Divider style={{ "margin": "0px" }} />
                <Layout.Content className="layoutHeader" style={{ "padding": "0px" }} >
                    <conditional.true value={radioDisplayValue === "valid"}>
                        {drawValid()}
                    </conditional.true>
                    <conditional.true value={radioDisplayValue === "prefix"}>
                        {drawPrefix()}
                    </conditional.true>
                    <conditional.true value={radioDisplayValue === "pubdate"}>
                        {drawPubdate()}
                    </conditional.true>
                </Layout.Content>
            </Layout> }
        </>
    )
}