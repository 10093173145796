import { Button, Divider, Space, Typography } from "antd";
import React from "react";

import { ucfirst } from "../../utils/Utils";
import TitleResults from "./TitleResults";

export default function TitleRelated(props) {
    const { isbn, related_count = {
        previous_edition: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        next_edition: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        comparable_titles: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        included_items: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        included_in: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        other_languages: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        },
        related: {
            fullname: "",
            shortname: "",
            shortkey: "",
            rows: [],
            count: 0,
        },
        other_titles: {
            fullname: "",
            shortname: "",
            shortkey: "",
            ids: [],
            count: 0,
        }
    }, drawComponent = () => { }, isbns = false, header = "", title = "" } = props;

    const getRelatedEditions = (_key) => {
        let _title = ucfirst(_key) + " for: " + title; 
        drawComponent(<TitleResults name={_title} related={_key} isbn={isbn} />)
    }

    const drawButtonDisplay = (key) => {
        let button_display = related_count[key].short_name + " (" + related_count[key].count + ")"; 
        return button_display;
    }


    const drawButtons = () => {
        return Object.keys(related_count).map((key, index) => {
            if ( related_count[key].count > 0) {
                return (
                    <Button 
                        key={index} 
                        size="small" 
                        className="mini-btn" 
                        onClick={() => getRelatedEditions(key)}>
                        <small>
                            {drawButtonDisplay(key)}
                        </small>
                    </Button>
                )
            }
        })
    }

    return (
        <Space size={3}>
            {drawButtons()}
        </Space>
    )
}